
import {
    defineComponent,
    ref,
    computed,
    createVNode,
    onMounted,
    toRefs,
    watch,
    nextTick,
} from 'vue';
import { useStore } from 'vuex';
// import { fakeData } from '@/views/SalesReporting2/PaymentTrackingReport/components/mockData';
import AInput from 'ant-design-vue/lib/input';
import { getProgramTrackingDetailSave } from '@/API/salesReporting';
import { message, Modal } from 'ant-design-vue';
import Decimal from '@/utils/closing/decimal';

interface SlotsType {
    title: string;
}

interface ChildrenType {
    dataIndex?: string;
    slots?: SlotsType;
    total?: number | string;
    width?: number;
    ellipsis?: boolean;
    customRender?: Function;
    customHeaderCell?: Function;
}

interface ColumnsType {
    cTitle: string;
    key: string;
    dataIndex?: string;
    display: boolean;
    canExpand?: boolean;
    sortAble?: boolean;
    expandStatus?: boolean;
    slots: SlotsType;
    width?: number;
    children?: Array<ChildrenType>;
    customRender?: Function;
    customHeaderCell?: Function;
}

/**
 * 限制数字格式为负数
 */
function constraintNegNumber(number: string, type = 'neg') {
    number = number.replace(/[^0-9\\-]/g, '');
    if (number === '-') {
        number = '';
    } else if (number !== '' && number !== '0') {
        if (number[0] !== '-' && type === 'neg') {
            number = `-${number}`;
        }
    }
    return number;
}

export default defineComponent({
    props: {
        trackingDetailData: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        saveParams: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },
    emits: ['save-success'],
    setup(props, { emit }) {
        const { trackingDetailData } = toRefs(props);
        const { dataList: dataSource, total: totalData } = toRefs(
            trackingDetailData.value
        );

        const renderInputCell = ({ record }: any, type: string) => {
            //* (New Add) 表示固定用于新增数据的行
            return createVNode(AInput, {
                value: record[type],
                class: ['narrow-row'],
                // type: 'number', //? 设置单元格只能输入数字
                onInput: ($event: any) => {
                    // record[type] = parseFloat($event.target.value);
                    if (type === 'toAlreadyReleasedDisplay') {
                        const number = constraintNegNumber($event.target.value);
                        record['toAlreadyReleased'] = number;
                        record['toAlreadyReleasedDisplay'] = new Decimal(
                            number
                        ).round(0);
                    } else if (type === 'pendingAdjDisplay') {
                        const number = constraintNegNumber(
                            $event.target.value,
                            'pos'
                        );
                        record['pendingAdj'] = number;
                        record['pendingAdjDisplay'] = new Decimal(number).round(
                            0
                        );
                    }
                },
            });
        };

        const renderNo = ({ index }: any) => {
            const obj = {
                children: index + 1,
                props: {} as any,
            };

            return obj;
        };

        const store = useStore();
        const fcSearchParams = computed(() => {
            return store.state.salesReport.fcSearchParams;
        });
        const make = computed(
            () => store.state.salesReport.fcSearchParams.make
        );
        const makeTotalStr = computed(() => {
            return `${make.value} Total`;
        });

        const distinctColor = (column: any, type: string) => {
            return { class: [type] };
        };
        const columnsAll = ref<ColumnsType[]>([
            {
                cTitle: 'No',
                key: 'No',
                dataIndex: 'No',
                display: true,
                slots: { title: 'No' },
                width: 50,
                customRender: renderNo,
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-blue'),
            },
            {
                cTitle: 'Program Code',
                key: 'programCode',
                display: true,
                slots: { title: 'programCode' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-blue'),
                children: [
                    {
                        dataIndex: 'programCode',
                        slots: { title: 'programCodeTotal' },
                        total: makeTotalStr.value,
                        // total: 'Total',
                        width: 200,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-blue'),
                    },
                ],
            },
            {
                cTitle: 'Program Name',
                key: 'programName',
                display: true,
                slots: { title: 'programName' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-blue'),
                children: [
                    {
                        dataIndex: 'programName',
                        slots: { title: 'programNameTotal' },
                        width: 250,
                        ellipsis: true,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-blue'),
                    },
                ],
            },
            {
                cTitle: 'Program Category',
                key: 'programCategory',
                display: true,
                slots: { title: 'programCategory' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-blue'),
                children: [
                    {
                        dataIndex: 'programCategory',
                        slots: { title: 'programCategoryTotal' },
                        width: 200,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-blue'),
                    },
                ],
            },
            {
                cTitle: 'Make',
                key: 'make',
                display: true,
                slots: { title: 'makeDisplay' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-blue'),
                children: [
                    {
                        dataIndex: 'makeDisplay',
                        slots: { title: 'makeTotal' },
                        width: 100,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-blue'),
                    },
                ],
            },
            {
                cTitle: 'BU',
                key: 'bu',
                display: true,
                slots: { title: 'bu' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-blue'),
                children: [
                    {
                        dataIndex: 'bu',
                        slots: { title: 'buTotal' },
                        width: 115,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-blue'),
                    },
                ],
            },
            {
                cTitle: 'Program Accrual',
                key: 'programAccrual',
                display: true,
                slots: { title: 'programAccrual' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-blue'),
                children: [
                    {
                        dataIndex: 'programAccrual',
                        slots: { title: 'programAccrualTotal' },
                        total: 0,
                        width: 150,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-blue'),
                    },
                ],
            },
            {
                cTitle: 'Payout BE',
                key: 'payoutBe',
                display: true,
                canExpand: true,
                expandStatus: false,
                slots: { title: 'payoutBe' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-blue'),
                children: [
                    {
                        dataIndex: 'payoutBE',
                        slots: { title: 'payoutBeTotal' },
                        total: 0,
                        width: 150,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-blue'),
                    },
                ],
            },
            {
                cTitle: 't/o already paid',
                key: 'toAlreadyPaid',
                display: false,
                sortAble: true,
                slots: { title: 'toAlreadyPaid' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-gray'),
                children: [
                    {
                        dataIndex: 'toAlreadyPaidDisplay',
                        slots: { title: 'toAlreadyPaidDisplay' },
                        total: 0,
                        width: 150,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-gray'),
                    },
                ],
            },
            {
                cTitle: 't/o pending (System)',
                key: 'pending',
                display: false,
                slots: { title: 'pending' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-gray'),
                children: [
                    {
                        dataIndex: 'toPendingSystem',
                        slots: { title: 'pendingTotal' },
                        total: 0,
                        width: 200,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-gray'),
                    },
                ],
            },
            {
                cTitle: 'Pending Adj.',
                key: 'pendingAdj',
                display: false,
                sortAble: true,
                slots: { title: 'pendingAdj' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-gray'),
                children: [
                    {
                        dataIndex: 'pendingAdjDisplay',
                        slots: { title: 'pendingAdjTotal' },
                        customRender: (rowobj: any) =>
                            renderInputCell(rowobj, 'pendingAdjDisplay'),
                        total: 0,
                        width: 150,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-gray'),
                    },
                ],
            },
            {
                cTitle: 'Close or not',
                key: 'closeOrNot',
                display: true,
                slots: { title: 'closeOrNot' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-blue'),
                children: [
                    {
                        dataIndex: 'closeOrNot',
                        slots: { title: 'closeOrNotTotal' },
                        // total: 0,
                        width: 100,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-blue'),
                    },
                ],
            },
            {
                cTitle: 'Possible Release',
                key: 'possibleRelease',
                display: true,
                sortAble: true,
                slots: { title: 'possibleRelease' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-blue'),
                children: [
                    {
                        dataIndex: 'possibleReleaseDisplay',
                        slots: { title: 'possibleReleaseTotal' },
                        total: 0,
                        width: 150,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-blue'),
                    },
                ],
            },
            {
                cTitle: 't/o already released',
                key: 'alreadyReleased',
                display: true,
                slots: { title: 'alreadyReleased' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-blue'),
                children: [
                    {
                        dataIndex: 'toAlreadyReleasedDisplay',
                        // dataIndex: 'toAlreadyReleased',
                        slots: { title: 'alreadyReleasedTotal' },
                        customRender: (rowobj: any) =>
                            renderInputCell(rowobj, 'toAlreadyReleasedDisplay'),
                        total: 0,
                        width: 150,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-blue'),
                    },
                ],
            },
            {
                cTitle: 't/o to-be released',
                key: 'toBeReleased',
                display: true,
                sortAble: true,
                slots: { title: 'toBeReleased' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-first-blue'),
                children: [
                    {
                        dataIndex: 'toBeReleasedDisplay',
                        slots: { title: 'toBeReleasedTotal' },
                        total: 0,
                        width: 150,
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-second-blue'),
                    },
                ],
            },
        ]);

        const spreadPayoutBe = () => {
            //* 点击加减号展开收起payoutbe
            const payoutBe = columnsAll.value[7];
            payoutBe.expandStatus = !payoutBe.expandStatus;
            const childrenOfPayoutBeIndex = [8, 9, 10];
            childrenOfPayoutBeIndex.forEach((index: number) => {
                columnsAll.value[index].display = !columnsAll.value[index]
                    .display;
            });
        };

        const columns = computed(() => {
            const cols = columnsAll.value.filter((col: any) => {
                return col.display;
            });
            return cols;
        });
        // const columns = ref<any>([]);
        // watch(
        //     columnsAll,
        //     (newVal: any) => {
        //         console.log(newVal);
        //         columns.value = newVal.filter((col: any) => {
        //             return col.display;
        //         });
        //     },
        //     {
        //         deep: true,
        //         immediate: true,
        //     }
        // );

        //* 动态计算 表头第二行 用于设置插槽 来绑定total数据
        const columnsSons = computed(() => {
            const sonList = columns.value.reduce((sons: any, father: any) => {
                if ('children' in father) {
                    return [...sons, ...father.children];
                }
                return [...sons];
            }, []);
            return sonList;
        });

        //* 计算表格宽度
        const colWidth = computed(() => {
            const width = columns.value
                .filter((col: any) => {
                    return col.display;
                })
                .reduce((wid: number, col: any) => {
                    if ('children' in col) {
                        return wid + col.children[0].width;
                    } else {
                        return col.width;
                    }
                }, 0);
            return width;
        });

        const showData = () => {
            // console.log(dataSource.value);
            console.log('ddddddd', trackingDetailData, dataSource, totalData);
        };

        //? 计算 Program Accrual total
        const sumColumn = (total: number, value: number) => {
            return total + value;
        };

        const countColumn = (total: number, type: string) => {
            return type === 'N' ? total + 1 : total;
        };

        const calculateTotals = () => {
            const calulateConfig = [
                {
                    index: 6,
                    colName: 'programAccrual',
                    total: 0,
                    calculateType: 'sum',
                },
                {
                    index: 7,
                    colName: 'payoutBE',
                    total: 0,
                    calculateType: 'sum',
                },
                {
                    index: 8,
                    colName: 'toAlreadyPaid',
                    total: 0,
                    calculateType: 'sum',
                },
                {
                    index: 9,
                    colName: 'toPendingSystem', // TODO 需要数字版的
                    total: 0,
                    calculateType: 'sum',
                },
                {
                    index: 10,
                    colName: 'pendingAdj',
                    total: 0,
                    calculateType: 'sum',
                },
                // {
                //     index: 11,
                //     colName: 'closeOrNot',
                //     total: 0,
                //     calculateType: 'count',
                // },
                {
                    index: 12,
                    colName: 'possibleRelease',
                    total: 0,
                    calculateType: 'sum',
                },
                {
                    index: 13,
                    colName: 'toAlreadyReleased',
                    total: 0,
                    calculateType: 'sum',
                },
                {
                    index: 14,
                    colName: 'toBeReleased',
                    total: 0,
                    calculateType: 'sum',
                },
            ];
            for (const row of dataSource.value) {
                for (const config of calulateConfig) {
                    if (config.calculateType === 'count') {
                        config.total = countColumn(
                            config.total,
                            row[config.colName]
                        );
                    } else {
                        let num = parseFloat(
                            row[config.colName].toString().replace(/,/g, '')
                        );
                        if (isNaN(num)) {
                            num = 0;
                        }
                        config.total = sumColumn(config.total, num);
                    }
                }
            }
            calulateConfig.map(
                (config: { index: number; colName: string; total: number }) => {
                    if (
                        columnsAll.value[config.index as number].children !==
                        undefined
                    ) {
                        (columnsAll.value[config.index]
                            .children as ChildrenType)[0].total = new Decimal(
                            config.total
                        ).round(0);
                    }
                }
            );
        };

        watch(
            dataSource,
            (newVal: any) => {
                if (columnsAll.value && columnsAll.value[1].children) {
                    columnsAll.value[1].children[0].total = makeTotalStr.value;
                }
                calculateTotals();
            },
            {
                deep: true,
            }
        );

        // const search = () => {
        //     calculateTotals();
        // };
        const saveWarningModalVisible = ref(false);
        const save = (flag = true) => {
            const paymentTrackingFCReqVO = (props as any).saveParams;
            const reportProgramTrackingDetailRespVOList = dataSource.value;
            const params = {
                flag, // true：需要校验； false：强制保存
                paymentTrackingFCReqVO,
                reportProgramTrackingDetailRespVOList,
            };
            getProgramTrackingDetailSave(params).then((res: any) => {
                if (res.code === '50090') {
                    message.error(res.message);
                } else if (res.code === '50091') {
                    // message.error(res.message);
                    saveWarningModalVisible.value = true;
                } else if (res.code === '0') {
                    emit('save-success');
                    message.success('Save successfully!');
                }
            });
        };

        const handleForceSave = () => {
            save(false);
            saveWarningModalVisible.value = false;
        };

        const handleCancel = () => {
            saveWarningModalVisible.value = false;
        };

        //  需要排序的四列 列属性里的key, 这里的key也对应了需要排列的四列 对应在返回数据中的数值型的字段
        const sortColumnsFlag = {
            alreadyPaid: 'descent',
            pendingAdj: 'descent',
            possibleRelease: 'descent',
            toBeReleased: 'descent',
        };

        const sortFun = {
            // 降序排列
            descent: (prop: string) => {
                return (a: any, b: any) => {
                    return b[prop] - a[prop];
                };
            },
            // 升序排列
            ascent: (prop: string) => {
                return (a: any, b: any) => {
                    return a[prop] - b[prop];
                };
            },
        };

        const sortData = (type: string, columnKey: string) => {
            if (dataSource.value.length === 0) return;
            sortColumnsFlag[columnKey] = type;
            const data = JSON.parse(JSON.stringify(dataSource.value));
            data.sort(sortFun[type](columnKey));
            dataSource.value = data;
        };

        // onMounted(() => {
        //     search();
        // });
        const colHeight = ref(500);
        const calculateHeight = () => {
            const table = document.getElementById('trackingDetailTable');
            const clinetHeight = document.body.clientHeight;
            if (table) {
                const top = table.getBoundingClientRect().top;
                colHeight.value = clinetHeight - top - 100;
            }
        };

        nextTick(() => {
            calculateHeight();
        });

        onMounted(() => {
            window.addEventListener('resize', calculateHeight);
        });

        return {
            showData,
            dataSource,
            columns,
            columnsSons,
            colWidth,
            spreadPayoutBe,
            save,
            sortData,
            sortColumnsFlag,
            colHeight,
            saveWarningModalVisible,
            handleForceSave,
            handleCancel,
        };
    },
});
